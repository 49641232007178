<template>
    <div class="box" style="overflow: hidden;">


        <el-row :gutter="10">
            <el-col :span="12" v-loading="MG_ElectricityPageDataLoading">
                <el-card style=" width: 100%;" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="12" style="
                     font-size: 18px; font-weight: bold
                    ">
                            <span v-if="formatCountOptionsRadioIs == 1">餐饮业态总计面积占比</span>
                            <span v-if="formatCountOptionsRadioIs == 2">餐饮业态总计数量占比</span>
                        </el-col>
                        <el-col :span="12" style="text-align: right;">
                            <el-radio-group v-model="formatCountOptionsRadioIs" size="small" @change="changeType">
                                <el-radio-button :label="1"><i class="el-icon-s-home"></i>&nbsp;面积</el-radio-button>
                                <el-radio-button :label="2"><i class="el-icon-s-grid"></i>&nbsp;数量</el-radio-button>
                            </el-radio-group>
                        </el-col>
                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <div style="position: relative;">
                        <div style="position: absolute;left: 10px;top: 0;font-size: 12px;font-weight: bold; ">
                            <div v-html="formatCountOptionsNumView2" v-if="formatCountOptionsRadioIs == 1">
                            </div>
                            <div v-html="formatCountOptionsNumView" v-if="formatCountOptionsRadioIs == 2">
                            </div>
                        </div>
                        <EchartsIndex ref="formatCountOptions" refId="formatCountOptions" :option="formatCountOptions"
                            @onclick="formatCountOptionsClick" styleStr="width: 100%; height: 290px"
                            v-if="this.MG_ElectricityPageData.FAP_Shop && this.MG_ElectricityPageData.FAP_Shop.length > 0" />
                        <el-empty description="暂无数据" v-else style="width: 100%; height: 290px"></el-empty>
                    </div>
                </el-card>

            </el-col>
            <el-col :span="12" v-loading="MG_ElectricityPageDataLoading">
                <el-card style=" width: 100%;" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="12" style="
                     font-size: 18px; font-weight: bold
                    ">
                            <span>商铺变动原因</span>

                        </el-col>
                        <el-col :span="12" style="text-align: right;">
                            <el-radio-group v-model="shopChangedIs" size="small" @change="shopChange">
                                <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;柱状图</el-radio-button>
                                <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;饼图</el-radio-button>
                            </el-radio-group>
                        </el-col>
                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <el-row v-if="shopChangedIs == 1">
                        <el-col>
                            <EchartsIndex refId="shopChangedBar" :option="shopChangedBar"
                                styleStr="width: 100%;height: 290px;" v-if="this.MG_ElectricityPageData.SCRP_Shop" />
                            <el-empty description="暂无数据" style="width: 100%;height: 290px;" v-else></el-empty>
                        </el-col>
                    </el-row>
                    <el-row v-if="shopChangedIs == 2">
                        <el-col>
                            <EchartsIndex refId="shopChangedPie" :option="shopChangedPie"
                                styleStr="width: 100%;height: 290px;" v-if="this.MG_ElectricityPageData.SCRP_Shop" />
                            <el-empty description="暂无数据" style="width: 100%;height: 290px;" v-else></el-empty>
                        </el-col>
                    </el-row>
                </el-card>

            </el-col>

        </el-row>
        <el-row :gutter="10" style="margin-top: 5px">
            <el-col :span="12" v-loading="MG_ElectricityPageDataLoading">
                <el-card style=" width: 100%;" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="12">
                            <span style="font-size: 18px; font-weight: bold" v-if="TopRadio === 1">不同业态商铺的用电负荷</span>
                            <span style="font-size: 18px; font-weight: bold"
                                v-if="TopRadio === 2">不同业态商铺不同版本的商铺数量</span>
                        </el-col>
                        <el-col :span="12" style="text-align: right;">
                            <el-radio-group v-model="TopRadio" size="small" @change="changeTopRadio">
                                <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;用电负荷</el-radio-button>
                                <el-radio-button :label="2"><i class="el-icon-s-data"></i>&nbsp;商铺数量</el-radio-button>
                            </el-radio-group>

                        </el-col>
                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <el-row v-if="TopRadio == 1">
                        <EchartsIndex refId="optionTopDataBarAll" :option="optionTopDataBarAll"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_All && MG_ElectricityPageData.LoadQuota_Electricity_All.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                    <el-row v-if="TopRadio == 2">
                        <EchartsIndex refId="optionTopDataBarCount" :option="optionTopDataBarCount"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_Count && MG_ElectricityPageData.LoadQuota_Electricity_Count.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>


                </el-card>
            </el-col>
            <el-col :span="12" v-loading="MG_ElectricityPageDataLoading">
                <el-card style=" width: 100%" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="24">
                            <span style="font-size: 18px; font-weight: bold">不同业态商铺的用电负荷平均指标</span>

                        </el-col>

                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <el-row>
                        <EchartsIndex refId="optionTopDataBar2" :option="optionTopDataBar2"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_Avg && MG_ElectricityPageData.LoadQuota_Electricity_Avg.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top: 5px">
            <el-col :span="24" v-loading="MG_ElectricityPageDataLoading">
                <el-card style=" width: 100%" class="InfoCard">
                    <el-row style="height: 35px;line-height: 35px;">
                        <el-col :span="24">
                            <span style="font-size: 18px; font-weight: bold">不同项目各餐饮业态商铺的用电负荷平均指标</span>

                        </el-col>

                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                    <el-row>
                        <EchartsIndex refId="optionCenterDataBar" :option="optionCenterDataBar"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AllProject_Avg && MG_ElectricityPageData.LoadQuota_Electricity_AllProject_Avg.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top: 5px">

            <el-col :span="24" v-loading="MG_ElectricityPageDataLoading">
                <el-card style="width: 100%" class="InfoCard">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane v-for="item in this.MG_ElectricityPageData?.ShopVersion" :key="item" :label="item"
                            :name="item">
                        </el-tab-pane>
                    </el-tabs>
                    <div>
                        <el-row style="line-height: 35px;height: 35px;">
                            <el-col :span="16">
                                <span style="font-weight: bold;font-size: 18px;" v-if="BottomRadio === 1">
                                    不同用电负荷指标商铺的商铺数量---{{ activeName }}
                                </span>
                                <span style="font-weight: bold;font-size: 18px;" v-if="BottomRadio === 2">
                                    不同用电负荷指标商铺的商铺数量占比---{{ activeName }}
                                </span>

                            </el-col>
                            <el-col :span="8" style="text-align: right;">
                                <el-radio-group v-model="BottomRadio" size="small" @change="changeBottomRadio">

                                    <el-radio-button :label="1"><i
                                            class="el-icon-s-data"></i>&nbsp;数量统计</el-radio-button>
                                    <el-radio-button :label="2"><i
                                            class="el-icon-s-data"></i>&nbsp;占比统计</el-radio-button>

                                </el-radio-group>

                            </el-col>
                        </el-row>
                        <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>
                        <el-row v-if="BottomRadio == 1">
                            <EchartsIndex refId="optionBottomDataQuota_Count" :option="optionBottomDataQuota_Count"
                                styleStr="width: 100%;height: 290px;"
                                v-if="MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count && MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count.length > 0" />
                            <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                        </el-row>
                        <el-row v-if="BottomRadio == 2">
                            <EchartsIndex refId="optionBottomDataQuota_Proportion"
                                :option="optionBottomDataQuota_Proportion" styleStr="width: 100%;height: 290px;"
                                v-if="MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion && MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion.length > 0" />
                            <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                        </el-row>
                    </div>

                </el-card>
            </el-col>

        </el-row>
        <el-row :gutter="10" style="margin-top: 5px">

            <el-col :span="24" v-loading="MG_ElectricityPageDataLoading">
                <el-card style="width: 100%;" class="InfoCard">
                    <el-tabs v-model="activeName2" type="card" @tab-click="handleClick2">
                        <el-tab-pane v-for="(item, index) in this.MG_ElectricityPageData?.ShopVersion"
                            :key="item + index" :label="item" :name="item">
                        </el-tab-pane>
                    </el-tabs>
                    <el-row style="font-weight: bold;font-size: 18px;height: 35px;line-height: 35px;">
                        <el-col :span="16">

                            <span v-if="BottomRadio2 === 1">不同面积区间的用电负荷量---{{ activeName2 }}</span>
                            <span v-if="BottomRadio2 === 2">不同面积区间的用电负荷平均指标---{{ activeName2 }}</span>
                            <span v-if="BottomRadio2 === 3">不同面积区间的商铺数量---{{ activeName2 }}</span>
                        </el-col>
                        <el-col :span="8" style="text-align: right;">
                            <el-radio-group v-model="BottomRadio2" size="small" @change="changeBottomRadio2">

                                <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;用电负荷</el-radio-button>
                                <el-radio-button :label="2"><i class="el-icon-s-data"></i>&nbsp;平均指标</el-radio-button>
                                <el-radio-button :label="3"><i class="el-icon-s-data"></i>&nbsp;商铺数量</el-radio-button>
                            </el-radio-group>

                        </el-col>
                    </el-row>
                    <el-row style="height: 1px;background-color: #EBEEF5;margin-top: 4px;"></el-row>

                    <el-row v-if="BottomRadio2 == 1">
                        <EchartsIndex refId="optionBottomDataAll" :option="optionBottomDataAll"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All && MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>

                    <el-row v-if="BottomRadio2 == 2">
                        <EchartsIndex refId="optionBottomDataAvg" :option="optionBottomDataAvg"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg && MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>
                    <el-row v-if="BottomRadio2 == 3">
                        <EchartsIndex refId="optionBottomDataCount" :option="optionBottomDataCount"
                            styleStr="width: 100%;height: 290px;"
                            v-if="MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count && MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count.length > 0" />
                        <el-empty description="暂无数据" style="width: 100%; height: 290px;" v-else></el-empty>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>

    </div>
</template>

<script>
// 引入echarts图表组件
import EchartsIndex from "@/components/Echarts/index";

export default {
    name: "MG_CateringDataPage", // 餐饮数据模块
    components: {
        EchartsIndex,
    },
    props: {
        typeList: {
            type: Array,
            default: () => [],
        },
        projectId: {
            type: String,
            default: "",
        },
        homeData: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            activeName: "",
            activeName2: "",
            MG_BaseInfo: {},
            formatCountInfoArr: [],
            formatCountOptionsRadioIs: 1,
            formatCountOptionsRadio: 1,
            // 业态数量占比数据
            formatCountOptions: {
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{b} : {c} ({d}%)",
                },
                label: {

                },
                yAxis: {
                    show: false,
                },
                xAxis: {
                    show: false,
                },
                series: [
                    {
                        type: "pie",
                        radius: "65%",
                        center: ["50%", "55%"],
                        selectedMode: "single",
                        data: [],
                    },
                ],
            },
            // 商铺变更原因柱状图数据
            shopChangedBar: {
                legend: {
                    data: [],
                    top: 5,
                },
                grid: {
                    top: 60,
                    bottom: "4%",
                },

                tooltip: {
                    trigger: "item",
                },
                xAxis: {
                    // show: false,
                    type: "category",
                    data: [],
                },
                yAxis: {
                    name: "变更次数",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLabel: {
                        formatter: "{value} 次",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    show: true,
                    type: "value",
                },
                label: {

                },
                series: [],
            },
            // 商铺变更原因饼图数据
            shopChangedPie: {
                legend: {
                    data: [],
                    top: 5,
                },

                tooltip: {
                    trigger: "item",
                    formatter: "{b} : {c} ({d}%)",
                },
                label: {

                },
                yAxis: {
                    show: false,
                },
                xAxis: {
                    show: false,
                },
                series: [
                    {
                        type: "pie",
                        radius: "65%",
                        center: ["50%", "55%"],
                        selectedMode: "single",

                        data: [

                        ],


                    },
                ],
            },
            shopChangedIs: 1,
            TopRadio: 1,
            TopRadio2: 1,
            BottomRadio: 1,
            BottomRadio2: 1,
            MG_ElectricityPageData: {

            },
            MG_ElectricityPageDataLoading: false,

            optionTopDataBarAll: {
                grid: {
                    top: 50, // 设置图表距离容器顶部的距离为10%\
                    left: 100,
                    right: 40,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }

                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    confine: true,
                    trigger: "item",
                    formatter: `{a} : {c}kW`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} kW",
                    },
                    name: "电负荷量（kW）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionCenterDataBar: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 60
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2)
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    confine: true,
                    formatter: (val) => {

                        if (val.componentType === "series") {
                            return `${val.seriesName}：${Number(val.value).toFixed(2)}W/㎡`
                        } else {
                            return `${val.name}`
                        }

                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        // rotate: "-45",
                        formatter: (val) => {
                            let num = Math.ceil(val.length / 8)
                            let str = ''
                            for (let i = 0; i < num; i++) {
                                str = str + val.slice(i * 8, (i + 1) * 8) + '\n'
                            }
                            return str
                        },
                    },
                },
                yAxis: {

                    axisLabel: {
                        formatter: "{value} W/㎡",
                    },
                    name: "平均指标（W/㎡）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionTopDataBarCount: {
                grid: {
                    top: 50, // 设置图表距离容器顶部的距离为10%\
                    left: 100,
                    right: 40,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }

                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    trigger: "item",
                    confine: true,
                    formatter: `{a} : {c}个`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} 个",
                    },
                    name: "商铺数量（个）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionTopDataBar2: {
                grid: {
                    top: 50, // 设置图表距离容器顶部的距离为10%\
                    left: 100,
                    right: 40,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2)
                        } else {
                            return ''

                        }
                    },
                },

                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    confine: true,
                    trigger: "item",
                    formatter: (val) => {

                        return `${val.seriesName}：${Number(val.value).toFixed(2)}W/㎡`
                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} W/㎡",
                    },
                    name: "平均指标（W/㎡）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },

            optionBottomDataQuota_Count: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    confine: true,
                    trigger: "item",
                    formatter: `{a} : {c}个`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        // rotate: "-20",

                    },

                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} 个",
                    },
                    name: "商铺数量（个）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataQuota_Proportion: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2) + '%'
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    confine: true,
                    trigger: "item",
                    formatter: (val) => {

                        return `${val.seriesName}：${Number(val.value).toFixed(2)}%`
                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        // rotate: "-20",

                    },

                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} %",
                    },
                    min: 0,
                    max: 100,

                    name: "占比统计（%）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataCount: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    confine: true,
                    trigger: "item",
                    formatter: `{a} : {c}个`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} 个",
                    },
                    name: "商铺数量（个）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataAll: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return val.value
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    confine: true,
                    trigger: "item",
                    formatter: `{a} : {c}kW`
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} kW",
                    },
                    name: "电负荷量（kW）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },
            optionBottomDataAvg: {
                grid: {
                    top: 60, // 设置图表距离容器顶部的距离为10%\
                    left: 120,
                    right: 70,
                    bottom: 30
                    // 其他grid属性...
                },
                label: {
                    show: true,
                    position: "top",
                    formatter: (val) => {

                        if (val.value) {
                            return Number(val.value).toFixed(2)
                        } else {
                            return ''
                        }
                    }
                },
                legend: {
                    //图例系列
                    show: true,
                    top: 5,
                    itemWidth: 14,
                    itemHeight: 14,
                    data: [],
                },
                tooltip: {
                    confine: true,
                    trigger: "item",
                    formatter: (val) => {
                        return `${val.seriesName}：${Number(val.value).toFixed(2)}W/㎡`
                    }
                },
                xAxis: {

                    type: "category",
                    data: [],
                    axisLabel: {
                        // 坐标轴刻度标签的相关设置。
                        show: true,
                        interval: 0,
                        //rotate: "-45",
                    },
                },
                yAxis: {
                    axisLabel: {
                        formatter: "{value} W/㎡",
                    },
                    name: "平均指标（W/㎡）",
                    nameLocation: "end",
                    nameTextStyle: {
                        width: 200,
                        align: "right",
                    },
                    axisLine: {
                        //坐标轴轴线
                        show: true,
                    },
                    axisTick: {
                        //坐标轴刻度
                        show: true,
                    },
                    type: "value",
                },
                series: [


                ],
            },

        };
    },
    computed: {
        formatCountOptionsNumView2() {
            return `<span> 餐饮商铺总面积：<span style="color:red">${this.MG_ElectricityPageData?.ShopTotalArea || 0} ㎡</span></span>`
        },
        formatCountOptionsNumView() {
            return `<span> 餐饮商铺总数量：<span style="color:red">${this.MG_ElectricityPageData?.ShopTotalNumber || 0} 个<span></span>`
        },

    },
    mounted() {

    },
    created() {


        this.getPageData();
    },
    methods: {
        shopChange() {

            if (this.MG_ElectricityPageData?.SCRP_Shop && this.MG_ElectricityPageData.SCRP_Shop.length > 0) {
                this.shopChangedBar.legend.data = []
                this.shopChangedBar.legend.data = this.MG_ElectricityPageData.SCRP_Shop
                this.shopChangedBar.label = {
                    show: true,
                    position: "top",
                    formatter: `{c}`
                }
                this.shopChangedBar.series = []
                this.MG_ElectricityPageData.SCRP_Shop.forEach(item => {
                    this.shopChangedBar.series.push({
                        name: item.name,
                        data: [item.value],
                        type: "bar",
                        emphasis: {
                            focus: "series",
                        },
                        barGap: "100%",
                        showBackground: true,
                        backgroundStyle: {
                            color: "rgba(180, 180, 180, 0.2)",
                        }
                    })
                })
                this.shopChangedPie.legend.data = []
                this.shopChangedPie.legend.data = this.MG_ElectricityPageData.SCRP_Shop || []
                this.shopChangedPie.label = {
                    show: true,

                    formatter: "{b} : {c}次 ({d}%)",
                }
                this.shopChangedPie.series[0].data = []
                this.shopChangedPie.series[0].data = this.MG_ElectricityPageData.SCRP_Shop || []
            }

        },
        handleClick() {
            this.BottomRadio = 1
            this.changeBottomRadio()
        },
        handleClick2() {
            this.BottomRadio2 = 1
            this.changeBottomRadio2()
        },
        changeoptionCenterDataBar() {
            if (this.MG_ElectricityPageData.LoadQuota_Electricity_AllProject_Avg && this.MG_ElectricityPageData.LoadQuota_Electricity_AllProject_Avg.length > 0) {
                this.optionCenterDataBar.legend.data = []
                this.optionCenterDataBar.series = []
                this.optionCenterDataBar.xAxis.data = []
                this.MG_ElectricityPageData.LoadQuota_Electricity_AllProject_Avg.forEach((item, index) => {
                    if (index === 0) {
                        item.value.forEach(val => {
                            this.optionCenterDataBar.xAxis.data.push(val.name)
                        })

                    }
                    this.optionCenterDataBar.legend.data.push(item.name);
                    // var data = item.value.map(val => {
                    //     return val.value
                    // })
                    // console.log(data, `${item.name}`)
                    // // 计算平均值但排除0
                    // var sum = data.reduce(function (a, b) {
                    //     return a + b;
                    // }, 0);
                    // var count = data.filter(function (value) {
                    //     return value !== 0;
                    // }).length;
                    // let itemData = []
                    // item.value.forEach(val => {
                    //     if (val.value > 0) {
                    //         itemData.push(val.name)
                    //     }
                    // })
                    // let nameStr = ``
                    // itemData.forEach(val => {
                    //     nameStr = nameStr + val + '<br/>'
                    // })
                    // console.log(sum, count, `${item.name}`)
                    // console.log(itemData, 'itemDataitemDataitemData')
                    // var average = (sum / count) || 0;

                    // console.log(average, `${item.name}`)
                    let arr = this.MG_ElectricityPageData?.LoadQuota_Electricity_Avg.find(val => {
                        return val.name === "V2.0"
                    })?.value || []
                    let average = arr.find(val => {
                        return val.name === item.name
                    }).value || 0
                    this.optionCenterDataBar.series.push({
                        name: item.name,
                        data: item.value,
                        type: "bar",

                        showBackground: true,
                        barGap: '100%',
                        emphasis: {
                            focus: "series",
                        },
                        markLine: { // 设置平均线
                            data: [
                                {
                                    type: "average",
                                    yAxis: average,
                                    name: `${item.name} 平均值：${average.toFixed(2) + 'W/㎡'}`,
                                }
                            ]
                        },
                        selectedMode: 'series',
                        // barWidth: 20,

                    })
                })
            }
        },
        formatCountOptionsClick(a) {
            if (this.formatCountOptionsRadioIs === 1) {
                if (a.name === '商铺总面积') {
                    this.formatCountInfoArr.pop()
                } else {
                    this.formatCountInfoArr = [...a.treePathInfo]
                }
            } else if (this.formatCountOptionsRadioIs === 2) {

                if (a.name === '商铺总数量') {
                    this.formatCountInfoArr.pop()
                } else {
                    this.formatCountInfoArr = [...a.treePathInfo]
                }
            }

        },
        changeType() {
            if (this.formatCountOptionsRadioIs === 1) {
                let obj = this.MG_ElectricityPageData.FAP_Shop.find(item => {
                    return item.name === 'ShopArea'
                })

                this.formatCountOptions.legend.data = obj.value || []
                this.formatCountOptions.label = {
                    show: true,
                    formatter: "{b} : {c}㎡ ({d}%)",
                }
                this.formatCountOptions.tooltip = {
                    trigger: "item",
                    formatter: "{b} : {c}㎡ ({d}%)",
                }
                this.formatCountOptions.series[0].data = obj.value || []

            } else if (this.formatCountOptionsRadioIs === 2) {
                let obj = this.MG_ElectricityPageData.FAP_Shop.find(item => {
                    return item.name === 'ShopCount'
                })

                this.formatCountOptions.legend.data = obj.value || []
                this.formatCountOptions.label = {
                    show: true,
                    formatter: "{b} : {c}个 ({d}%)",
                }
                this.formatCountOptions.tooltip = {
                    trigger: "item",
                    formatter: "{b} : {c}个 ({d}%)",
                }
                this.formatCountOptions.series[0].data = obj.value || []

            }
        },
        changeBottomRadio() {
            if (this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count && this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count.length > 0) {
                this.optionBottomDataQuota_Count.legend.data = []
                this.optionBottomDataQuota_Count.series = []
                this.optionBottomDataQuota_Count.xAxis.data = []



                this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Count.forEach((item) => {

                    if (item.name === this.activeName) {
                        item.value.forEach((val, index) => {
                            if (index === 0) {
                                val.value.forEach(el => {
                                    this.optionBottomDataQuota_Count.xAxis.data.push(el.name)
                                })

                            }
                            this.optionBottomDataQuota_Count.legend.data.push(val.name);
                            this.optionBottomDataQuota_Count.series.push({
                                name: val.name,
                                data: val.value,
                                type: "bar",

                                showBackground: true,
                                barGap: '100%',
                                emphasis: {
                                    focus: "series",
                                },
                                selectedMode: 'series',
                                // barWidth: 20,

                            })
                        })

                    }
                })
            }

            if (this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion && this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion.length > 0) {
                this.optionBottomDataQuota_Proportion.legend.data = []
                this.optionBottomDataQuota_Proportion.series = []
                this.optionBottomDataQuota_Proportion.xAxis.data = []
                this.MG_ElectricityPageData.LoadQuota_Electricity_Quota_Proportion.forEach((item, index) => {

                    if (item.name === this.activeName) {
                        item.value.forEach((val, index) => {
                            if (index === 0) {
                                val.value.forEach(el => {
                                    this.optionBottomDataQuota_Proportion.xAxis.data.push(el.name)
                                })
                            }
                            this.optionBottomDataQuota_Proportion.legend.data.push(val.name);
                            this.optionBottomDataQuota_Proportion.series.push({
                                name: val.name,
                                data: val.value,
                                type: "bar",

                                showBackground: true,
                                barGap: '100%',
                                emphasis: {
                                    focus: "series",
                                },
                                selectedMode: 'series',
                                // barWidth: 20,

                            })
                        })

                    }

                })
            }

        },
        changeBottomRadio2() {
            if (this.BottomRadio2 === 1) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All && this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All.length > 0) {
                    this.optionBottomDataAll.legend.data = []
                    this.optionBottomDataAll.series = []
                    this.optionBottomDataAll.xAxis.data = []

                    this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_All.forEach((item,) => {


                        if (item.name === this.activeName2) {
                            item.value.forEach((val, index) => {
                                if (index === 0) {
                                    val.value.forEach(el => {
                                        this.optionBottomDataAll.xAxis.data.push(el.name)
                                    })

                                }
                                this.optionBottomDataAll.legend.data.push(val.name);
                                this.optionBottomDataAll.series.push({
                                    name: val.name,
                                    data: val.value,
                                    type: "bar",

                                    showBackground: true,
                                    barGap: '100%',
                                    emphasis: {
                                        focus: "series",
                                    },
                                    selectedMode: 'series',
                                    // barWidth: 20,

                                })
                            })

                        }

                    })
                }
            } else if (this.BottomRadio2 === 2) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg && this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg.length > 0) {
                    this.optionBottomDataAvg.legend.data = []
                    this.optionBottomDataAvg.series = []
                    this.optionBottomDataAvg.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Avg.forEach((item) => {
                        if (item.name === this.activeName2) {
                            item.value.forEach((val, index) => {
                                if (index === 0) {
                                    val.value.forEach(el => {
                                        this.optionBottomDataAvg.xAxis.data.push(el.name)
                                    })

                                }
                                this.optionBottomDataAvg.legend.data.push(val.name);

                                this.optionBottomDataAvg.series.push({
                                    name: val.name,
                                    data: val.value,
                                    type: "bar",
                                    // stack: item.name,
                                    showBackground: true,
                                    barGap: '100%',
                                    emphasis: {
                                        focus: "series",
                                    },
                                    selectedMode: 'series',
                                    // barWidth: 20,

                                })
                            })
                        }


                    })
                }
            } else if (this.BottomRadio2 === 3) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count && this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count.length > 0) {
                    this.optionBottomDataCount.legend.data = []
                    this.optionBottomDataCount.series = []
                    this.optionBottomDataCount.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_AreaRange_Count.forEach((item) => {
                        if (item.name === this.activeName2) {
                            item.value.forEach((val, index) => {
                                if (index === 0) {
                                    val.value.forEach(el => {
                                        this.optionBottomDataCount.xAxis.data.push(el.name)
                                    })

                                }
                                this.optionBottomDataCount.legend.data.push(val.name);

                                this.optionBottomDataCount.series.push({
                                    name: val.name,
                                    data: val.value,
                                    type: "bar",
                                    // stack: item.name,
                                    showBackground: true,
                                    barGap: '100%',
                                    emphasis: {
                                        focus: "series",
                                    },
                                    selectedMode: 'series',
                                    // barWidth: 20,

                                })
                            })
                        }



                    })
                }
            }

        },
        changeTopRadio() {
            if (this.TopRadio === 1) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_All && this.MG_ElectricityPageData.LoadQuota_Electricity_All.length > 0) {
                    this.optionTopDataBarAll.legend.data = []
                    this.optionTopDataBarAll.series = []
                    this.optionTopDataBarAll.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_All.forEach((item, index) => {
                        if (index === 0) {
                            item.value.forEach(val => {
                                this.optionTopDataBarAll.xAxis.data.push(val.name)
                            })

                        }
                        this.optionTopDataBarAll.legend.data.push(item.name);
                        this.optionTopDataBarAll.series.push({
                            name: item.name,
                            data: item.value,
                            type: "bar",

                            showBackground: true,
                            barGap: '100%',
                            emphasis: {
                                focus: "series",
                            },
                            selectedMode: 'series',
                            // barWidth: 20,

                        })
                    })
                }
            } else if (this.TopRadio === 2) {
                if (this.MG_ElectricityPageData.LoadQuota_Electricity_Count && this.MG_ElectricityPageData.LoadQuota_Electricity_Count.length > 0) {
                    this.optionTopDataBarCount.legend.data = []
                    this.optionTopDataBarCount.series = []
                    this.optionTopDataBarCount.xAxis.data = []
                    this.MG_ElectricityPageData.LoadQuota_Electricity_Count.forEach((item, index) => {
                        if (index === 0) {
                            item.value.forEach(val => {
                                this.optionTopDataBarCount.xAxis.data.push(val.name)
                            })

                        }
                        this.optionTopDataBarCount.legend.data.push(item.name);
                        this.optionTopDataBarCount.series.push({
                            name: item.name,
                            data: item.value,
                            type: "bar",

                            showBackground: true,
                            barGap: '100%',
                            emphasis: {
                                focus: "series",
                            },
                            selectedMode: 'series',
                            // barWidth: 20,

                        })
                    })
                }
            }

        },
        changeTopRadio2() {
            if (this.MG_ElectricityPageData.LoadQuota_Electricity_Avg && this.MG_ElectricityPageData.LoadQuota_Electricity_Avg.length > 0) {
                this.optionTopDataBar2.legend.data = []
                this.optionTopDataBar2.series = []
                this.optionTopDataBar2.xAxis.data = []
                this.MG_ElectricityPageData.LoadQuota_Electricity_Avg.forEach((item, index) => {
                    if (index === 0) {
                        item.value.forEach(val => {
                            this.optionTopDataBar2.xAxis.data.push(val.name)
                        })

                    }
                    this.optionTopDataBar2.legend.data.push(item.name);

                    this.optionTopDataBar2.series.push({
                        name: item.name,
                        data: item.value,
                        type: "bar",

                        showBackground: true,
                        barGap: '100%',
                        emphasis: {
                            focus: "series",
                        },
                        selectedMode: 'series',
                        // barWidth: 20,

                    })
                })
            }
        },
        getPageData() {
            this.MG_ElectricityPageDataLoading = true
            this.$http
                .post('/Base_ProjectManage/Project_DataAnalysis/GetMG_CateringReturnData_HR_Group', []).then(res => {
                    this.MG_ElectricityPageDataLoading = false
                    if (res.Success) {
                        // this.$set(this.MG_BaseInfo, 'FAP_Shop', res.Data.FAP_Shop || {})
                        this.MG_ElectricityPageData = res.Data || {}
                        this.activeName = res.Data.ShopVersion[0] || ''
                        this.activeName2 = res.Data.ShopVersion[0] || ''
                        this.changeType()
                        this.shopChange()
                        this.changeoptionCenterDataBar()
                        // this.MG_ElectricityPageData = res.Data || {}
                        this.changeTopRadio()
                        this.changeTopRadio2()



                        this.changeBottomRadio()
                        this.changeBottomRadio2()

                    } else {
                        // this.MG_ElectricityPageData = {}
                        // this.$message.error('获取用电统计数据失败');
                    }
                }).catch(() => {
                    // this.MG_ElectricityPageDataLoading = false
                    // this.MG_ElectricityPageData = {}
                    // this.$message.error('获取用电统计数据失败');

                })

        },

        handleBack() {
            this.$emit("changePageblock", true);
        },
    },
};
</script>

<style scoped lang="scss">
.box {
    padding: 20px;
    box-sizing: border-box;
}

.dataStripe_title {
    display: block;
    line-height: 45px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}

// 数据条卡片内显示值
.dataStripe_value {
    display: block;
    line-height: 45px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}

.dataStripe_i {
    font-size: 40px;
    color: #ffffff;
    line-height: 90px;
    // margin-left: 20px;
}

.InfoCard {
    border-radius: 10px;
    padding: 0px 5px;
    box-sizing: border-box;
    border: 5px solid #c3c3c3;
}

::v-deep.ant-table-header {
    overflow-x: hidden !important;
}

::v-deep .el-card__body {
    padding: 10px;
}
</style>